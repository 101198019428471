/* eslint-disable */
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { saveAs } from "file-saver";
import MaterialReactTable from "material-react-table";
import dayjs from "dayjs";
import * as XLSX from "xlsx";

import { SiteDTO, deleteSite, exportSites, uploadSites, exportRecsDetailBySite, enableSite } from "api";
import { getMultipleRecDocumentDetails } from "api/rec";

import { EsgEditIcon, PlusIcon, SitemultiRegion, EsgRedPowerIcon, EsgGreenPowerIcon, EsgNotesIcon, EsgExcelExport, EsgSearchIcon, EmissionFactorReferencesIcon } from "assets";

import {
  COLORS,
  DeleteDialog,
  EMPTY_CELL_VALUE,
  H5BoldInter,
  Loading,
  TextField,
  useDialogControls,
} from "components";
import { RecUploadButton } from "components/buttons/rec-upload-button";

import { useGetAllowedValues, useNotify, useSites } from "hooks";

import { QUERY_KEYS } from "consts";

import { USER_ROLES, useNewBreadcrumb, useUserRole } from "recoils";

import { convertBlankStringsToNull } from "utils";

import { SiteAccordion } from "./components";
import { AddSiteModal } from "./components/site-editor-form/components/AddSiteModal";
import { SiteColumns } from "./components/site-editor-form/components/SiteColumns";

import { getAllowedValues } from "../sites/components";
import { SITES_PAGE } from "../sites/consts";

export const TabSite = ({ state }: { state: string }) => {
  useNewBreadcrumb(SITES_PAGE.TITLE);
  const { data: sites, isLoading, refetch, isRefetching } = useSites();
  const userRole = useUserRole();
  const [addSiteModalOpen, setAddSiteModalOpen] = useState(false);
  const [EditSiteOpen, setEditSiteOpen] = useState(false);
  const [toDeactivateSite, setDeactivateSite] = useState<any | undefined>();
  const [EditSite, setEditSite] = useState<any | undefined>();
  const [editNotesOpen, setEditNotesOpen] = useState<boolean>(false);
  const [clickType, setClickType] = useState<string>("");
  const [isDataLoading, setIsLoading] = useState<boolean>(false);

  const activatedSites = sites?.filter((t) => t.enabled === true);
  const deActivatedSites = sites?.filter((t) => t.enabled === false);

  // Expanded Site Logic 
  const expanded: number[] = [];
  const expandedSite =
    sessionStorage.getItem("expanded-sites") &&
    JSON.parse(sessionStorage.getItem("expanded-sites") as string);
  const indexObject: any = {};
  const index: any = expandedSite &&  state === "active" ? activatedSites?.map((site) => site.id).indexOf(expandedSite) : deActivatedSites?.map((site) => site.id).indexOf(expandedSite) ;
  if (index > -1) {
    indexObject[index] = true;
  }

  const currentSites = useMemo(() => state === "active" ? activatedSites : deActivatedSites, [sites])

  const [searchValue, setSearchValue] = useState("");
  const [filterSites, setFilterSites] = useState<SiteDTO[]>(
    activatedSites || [],
  );

  const notify = useNotify();
  const navigate = useNavigate();

  const { data: allowedValues } = useGetAllowedValues();
  const { mutateAsync: disableSiteRequest } = useMutation(deleteSite);
  const { mutateAsync: activeSite } = useMutation(enableSite);

  const deleteDialogControls = useDialogControls();

  const handleSearchChange = (event: any) => {
    const { value } = event.target;
    setSearchValue(value);
    handleSearch(value);
  };

  const handleSearch = (value: string) => {
    if (value === "") {
      setFilterSites(sites || []);
    } else {
      const filteredActivatedRecords = sites?.filter(
        (record) =>
          (record.name &&
            record.name.toLowerCase().includes(value.toLowerCase())) ||
          (record.type &&
            record.type.toLowerCase().includes(value.toLowerCase())) ||
          (record.city &&
            record.city.toLowerCase().includes(value.toLowerCase())) ||
          (record.country &&
            record.country.toLowerCase().includes(value.toLowerCase())),
      );
      setFilterSites(filteredActivatedRecords || []);
    }
  };

  const siteDetailsColumns = [
    {
      accessorKey: "name",
      header: "Site Name",
      size: 60,
      Cell: ({ cell }: any) => {
        const value = cell.getValue() as string;

        if (!value) {
          return <Box sx={{ paddingLeft: "15px" }}>{EMPTY_CELL_VALUE}</Box>;
        }
        return (
          <Tooltip title={value}>
            <Box
              sx={{
                maxWidth: "160px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {value}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "type",
      header: "Type of site",
      size: 40,
    },
    {
      accessorKey: "businessUnit",
      header: "Business Unit",
      size: 40,
      Cell: ({ cell }: any) => {
        const value = cell.getValue() as string;
        if (!value) {
          return <Box sx={{ paddingLeft: "15px" }}>{EMPTY_CELL_VALUE}</Box>;
        }
        return (
          <Tooltip title={value}>
            <Box
              sx={{
                maxWidth: "160px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {value}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "city",
      header: "City",
      size: 100,
    },
    {
      accessorKey: "country",
      header: "Country",
      size: 40,
    },
    {
      accessorKey: "countryRegion",
      header: "Region",
      size: 40,
    },
    {
      accessorKey: "region",
      header: "Electricity Region Code",
      Header: <Box>Electricity Region Code</Box>,
      size: 200,
      // eslint-disable-next-line
      Cell: ({ row }: any) => {
        const { multipleRegion: regionmultiple, region } = row.original;

        let message = "";
        if (region === null) {
          message = regionmultiple
            ? "Multiple region codes found"
            : "Missing region code";
        }

        return (
          <div
            style={{
              display: "flex",
            }}
          >
            {region === null ? (
              <span style={{ color: "#D86262" }}>
                <SitemultiRegion
                  style={{
                    marginRight: "2px",
                    paddingTop: regionmultiple ? "0px" : "4px",
                  }}
                />
                {message}{" "}
              </span>
            ) : (
              <span>{region} </span>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: "address",
      header: "Street Address",
      size: 40,
      // eslint-disable-next-line
      Cell: ({ cell }: any) => {
        const value = cell.getValue() as string;

        if (!value) {
          return <Box sx={{ paddingLeft: "15px" }}>{EMPTY_CELL_VALUE}</Box>;
        }
        return (
          <Tooltip title={value}>
            <Box
              sx={{
                alignItems: "center",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {value}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "zipCode",
      header: "ZipCode",
      size: 40,
    },
    {
      accessorKey: "notes",
      header: "Notes",
      size: 40,
    },
  ];

  const handleEditSite = (rowData: any, type: string) => {
    setClickType(type)
    setEditSiteOpen(true);
    setEditSite(rowData);
  };

  const handleEditSiteNotes = (rowData: any, type: string) => {
    setClickType(type)
    setEditNotesOpen(true);
    setEditSite(rowData);
  };

  const handleCloseSiteModal = () => {
    if (addSiteModalOpen) {
      setAddSiteModalOpen(false);
    }
    if (EditSiteOpen) {
      setEditSiteOpen(false);
      refetch();
    }
    if (editNotesOpen) {
      setEditNotesOpen(false);
      refetch();
    }
    setEditSite(undefined);
  };

  const handleDeActivateSite = (siteId: number) => {
    if (siteId) {
      disableSiteRequest(siteId, {
        onError: () => {
          deleteDialogControls.close();
          notify.error(`Some error has happen while de-activating the  site!`);
        },
        onSuccess: () => {
          deleteDialogControls.close();
          notify.success(`Site successfully De-Activated!`);
          refetch();
          // window.location.reload();
        },
      });
    }
  };

  const handleReActivateSite = (siteId: number) => {
    if (siteId) {
      activeSite(siteId, {
        onError: () => {
          deleteDialogControls.close();
          notify.error(`Some error has happen while re-activating the site!`);
        },
        onSuccess: () => {
          deleteDialogControls.close();
          notify.success(`Site successfully Re-Activated!`);
          refetch();
          // window.location.reload();
        },
      });
    }
  };

  const activatedRecords =
    (activatedSites &&
      JSON.parse(
        JSON.stringify(searchValue.length > 0 ? filterSites : activatedSites),
      )) ||
    [];

  const deActivatedRecords =
    (deActivatedSites && JSON.parse(JSON.stringify(deActivatedSites))) || [];

  const exportToExcel = async () => {
    setIsLoading(true)
    notify.success(`Data is being prepared, this might take a moment.`);
    try {
      setIsLoading(true)
      const sites: any = await exportSites();
      const recs = await exportRecsDetailBySite();
      const siteSetup = sites.map((site: any) => ({
        "Site Name": site.name,
        "Customer Site ID": site.customerSiteId,
        "Business Unit": site.businessUnit,
        "Type of site": site.type,
        Status: site.status,
        "Street address": site.address,
        City: site.city,
        State: site.state,
        Country: site.country,
        "Country Region": site.countryRegion,
        "Zip Code": site.zipCode,
        "Start Date": new Date(site.startDate),
        "End Date": new Date(site.endDate),
        Notes: site.notes,
      }));

      const utilityProviderInfo = sites.flatMap((site: any) =>
        site.collectors.map((collector: any) => ({
          "Site Name": site.name,
          "Customer Site ID": site.customerSiteId,
          "Collector ID" : `${collector?.accountNumber}/${collector?.meterNumber}/${collector?.utilityTypeName}`,
          "Account Number": collector.accountNumber,
          "Meter Number": collector.meterNumber,
          "Utility Type": collector.utilityTypeName,
          utilitySubType: collector.utilitySubType,
          "Utility Provider": collector.providerName,
          "Document format": collector.allowedDocuments?.map((t: string) => t).join(", "),
          Frequency: collector.frequency,
          "Consumption Unit": collector.consumptionUnit,
          Currency: collector.currency,
          "GHG Emissions": collector?.scope,
          "Unit Cost": collector?.unitCost,
          "Spend Based": collector?.spendBased,
          "Start Date": new Date(collector?.startDate),
          "End Date": new Date(collector?.endDate),
        })),
      );
     
      const parameterBasedEstimatesInfo = sites.flatMap((site: any) =>
        site.estimates.map((estimate: any) => ({
          "Site Name": estimate.siteName,
          "Customer Site Id": site.customerSiteId,
          "Estimate Name": estimate.estimateName,
          "Utility Type": estimate.utilityName,
          "Estimate Type": estimate.estimateType,
          "Estimation Value": estimate.estimateHeadCount,
          "Estimated Usage per Day": estimate.estimateUsage,
          "Consumption Unit": estimate.estimateUsageUnit,
          "Start Date": estimate.estimateStartDate,
          "End Date": estimate.estimateEndDate,
          "GHG Emission": estimate.scope,
          "Notes": estimate.notes,
        })),
      );

      const dataProvidersInfo = sites.flatMap((site: any) =>
        site.users.map((user: any) => ({
          "Site Name": site.name,
          "Customer Site ID": site.customerSiteId,
          "Data manager": user.firstName+ " " + user.lastName,
          Email: user.email,
        })),
      );

      const renewableEnergyCertificatesInfo: any = [];

      const fetchRECDetails = async (recId: any) => {
        if (recId?.length > 0) {
          const recResponse = await getMultipleRecDocumentDetails(recId);
          return recResponse.data;
        } else {
          return null;
        }
      };

      const formatCollectorString = (collectorRecsDtoList: any) =>
        collectorRecsDtoList
          .map(
            ({ siteName, meterNumber = "N/A", accountNumber = "N/A" }: { siteName: string, meterNumber: string, accountNumber: string }) =>
              `${siteName} - ${meterNumber} - ${accountNumber}`
          )
          .join("\n");

      const recCache = new Map();

      const getRecData = async (recId: string) => {
        if (recCache.has(recId)) {
          return recCache.get(recId);
        } else {
          const data = await fetchRECDetails(recId);
          recCache.set(recId, data);
          return data;
        }
      }

      const recIds = sites.flatMap((item: any) =>
        item.collectors
          .filter((collector: any) => collector.recsDetail && collector.recsDetail.recId !== undefined)
          .map((collector: any) => collector.recsDetail.recId)
      );

      const recData = await getRecData(recIds);


      recData && recData.forEach((recItem: any) => {
        let matchingScope = null;

        // Iterate over sites to find relevant scope and other details
        for (const site of sites) {
          const relevantCollectors = site.collectors.filter((collector: any) => collector.recs);

          for (const { recsDetail, scope } of relevantCollectors) {
            // If there's a condition to match recItem with scope, do it here
            if (scope) {
              matchingScope = scope;
              break; // Stop the inner loop when a match is found
            }
          }

          if (matchingScope) {
            break; // Stop the outer loop once a match is found
          }
        }

        // Push the record with the found scope or default scope handling
        renewableEnergyCertificatesInfo.push({
          "Utility Provider": recItem.utilityProvider,
          "Utility Type": recItem.utilityTypeName,
          "Start Date": recItem.startDate,
          "End Date": recItem.endDate,
          Scope: matchingScope, // Add a default or null if no match found
          Collector: formatCollectorString(recItem.collectorRecsDtoList),
        });
      });

      const recDetailsBySite = recs.flatMap((recData: any) => ({
        "Site Name": recData.siteName,
        "Customer Site ID": recData.customerSiteId,
        "REC Country": recData.country,
        "REC Provider": recData.recsProvider,
        "REC Utility Type": recData.recsUtilityType,
        "REC Start Date": dayjs(recData.recsStartDate).format("YYYY-MM-DD"),
        "REC End Date": dayjs(recData.recsEndDate).format("YYYY-MM-DD"),
        "REC Percentage": recData.recsPercentage,
        "Collector": recData.collector?.replace("==>", "; "),
        "Scope of Collector": recData.scope,
        "Utility Type of Collector": recData.utilityTypeOfCollector
      }));

      const wb = XLSX.utils.book_new();

      const wsSiteSetup = XLSX.utils.json_to_sheet(siteSetup);

      const range = XLSX.utils.decode_range(wsSiteSetup['!ref'] as string);
      for (let row = range.s.r + 1; row <= range.e.r; row++) {
        const startDateCell = XLSX.utils.encode_cell({ r: row, c: 12 });
        if (wsSiteSetup[startDateCell]) wsSiteSetup[startDateCell].z = 'yyyy-mm-dd';

        const endDateCell = XLSX.utils.encode_cell({ r: row, c: 13 });
        if (wsSiteSetup[endDateCell]) wsSiteSetup[endDateCell].z = 'yyyy-mm-dd';
      }

      const wsUtilityProviderInfo = XLSX.utils.json_to_sheet(utilityProviderInfo);
      const wsParameterBasedEstimatesInfo = XLSX.utils.json_to_sheet(
        parameterBasedEstimatesInfo,
      );
      const wsDataProvidersInfo = XLSX.utils.json_to_sheet(dataProvidersInfo);
      const wsRenewableEnergyInfo = XLSX.utils.json_to_sheet(
        renewableEnergyCertificatesInfo,
      );

      const wsRecDetailsBySite = XLSX.utils.json_to_sheet(recDetailsBySite);

      XLSX.utils.book_append_sheet(wb, wsSiteSetup, "Site Setup");
      XLSX.utils.book_append_sheet(
        wb,
        wsUtilityProviderInfo,
        "Utility Provider Info",
      );
      XLSX.utils.book_append_sheet(
        wb,
        wsParameterBasedEstimatesInfo,
        "Parameter Estimates Setup",
      );
      XLSX.utils.book_append_sheet(
        wb,
        wsDataProvidersInfo,
        "Data Providers Info",
      );
      XLSX.utils.book_append_sheet(wb, wsRenewableEnergyInfo, "RECS Info");
      XLSX.utils.book_append_sheet(wb, wsRecDetailsBySite, "REC details by site");

      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary", cellDates: true });

      const s2ab = (s: any) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      };

      saveAs(
        new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
        "Site_Management_Data.xlsx",
      );
    } catch (e) {
      setIsLoading(false)
    }
    setIsLoading(false)
  };

  const handleEmissionFactorReference = (siteId: number | string) => {
    navigate(`/references?s=${siteId}`)
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2, mb: 2 }}>
            <RecUploadButton
              label="Upload Data"
              uploadApiCall={uploadSites}
              queryKeyToInvalidate={QUERY_KEYS.SITES}
              availableToRoles={[USER_ROLES.ADMIN]}
              extraReqParams={[["configuration", "true"]]}
            />
            <Button
              sx={{ height: "36px", width: "140px", borderRadius: 2, marginTop: "10px" }}
              variant="contained"
              onClick={() => setAddSiteModalOpen(true)}
              startIcon={<PlusIcon />}
              disabled={userRole.isAuditor || userRole.isDataProvider}
            >
              Add Site
            </Button>
            <Button
              sx={{ height: "36px", width: "200px", borderRadius: 2, marginTop: "10px" }}
              variant="contained"
              onClick={exportToExcel}
              startIcon={<EsgExcelExport />}
              disabled={isDataLoading}
            >
              {isDataLoading ? "Loading..." : "Export to Excel"}
            </Button>
          </Box>
        </Box>
        <Box
          display="flex"
          gap="2"
          justifyContent="flex-end"
          alignItems="center"
          mb={2}
          sx={{
            width: "240px",
            height: "36px",
            borderRadius: 2,
            border: "1px solid  black",
            marginTop: "7px",
          }}
        >
          <IconButton>
            <EsgSearchIcon />
          </IconButton>
          <TextField
            placeholder="Search sites..."
            value={searchValue}
            onChange={handleSearchChange}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              border: "none",
              "& input": {
                padding: 0,
                fontSize: "14px",
              },
              "& fieldset": {
                border: "none",
              }
            }}
          />
        </Box>
      </Box>
      {isLoading ? (
        <Loading />
      ) : (
        <MaterialReactTable
          state={{ isLoading }}
          enableSorting={true}
          columns={SiteColumns}
          enableGlobalFilter={false}
          data={searchValue.length > 0 ? filterSites : currentSites!}
          enablePagination
          muiTableContainerProps={{
            sx: {
              "& thead": {
                position: "sticky",
                top: 0,
                zIndex: 9,
              },
              "& thead tr:first-of-type th:first-of-type > div > div > div": {
                minWidth: "36px",
                width: "36px",
              },
              "& thead tr:first-of-type th:nth-child(2)": {
                minWidth: "160px",
                width: "160px",
              },
              "& thead tr:first-of-type th:nth-child(4)": {
                minWidth: "190px",
                width: "190px",
              },
              "& thead tr:first-of-type th:nth-child(5)": {
                minWidth: "140px",
                width: "140px",
              },
              "& tbody tr:nth-child(even)": {
                height: 0,
              },
              "& tbody": {
                maxWidth: '100%',
                "& tr:nth-child(even) > td:first-of-type > div: first-of-type > div > div": {
                  width: 'calc(100vw - 200px)',
                },
              },
              "& tbody tr td:first-of-type": {
                paddingLeft: "20px",
              },
              "& tbody tr:first-of-type td > p": {
                display: "flex",
                width: "100%",
                justifyContent: "center",
              },
              height: "calc(100vh - 300px)",
              border: "1px solid #D9D9D9",
              borderRadius: "10px",
              minWidth: "100%",
              width: "100%",
              maxWidth: "100%",
              "@media (min-width: 600px)": {
                width: "100%",
              },
              "@media (min-width: 960px)": {
                width: "1100px",
              },
              "@media (min-width: 1280px)": {
                width: "1100px",
              },
              "@media (min-width: 1440px)": {
                width: "1100px",
              },
              "@media (min-width: 1680px)": {
                width: "1300px",
              },
              "@media (min-width: 2400px)": {
                width: "100%",
              },
            },
          }}
          muiTablePaperProps={{
            sx: {
              borderRadius: "10px",
              boxShadow: "none",
            },
          }}
          muiTopToolbarProps={{
            sx: {
              display: "none",
            },
          }}
          muiBottomToolbarProps={{
            sx: {
              display: "none",
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              lineHeight: "30px",
              "& .MuiBadge-root": {
                display: "none",
              },
            },
          }}
          muiTableHeadRowProps={{
            sx: {
              height: "45px",
              backgroundColor: "#F8FAFC",
            },
          }}
          muiTableBodyRowProps={{
            sx: {
              height: "45px",
              backgroundColor: "white",
              minWidth: '100%',
              width: '100%',
              maxWidth: '100%',
              '@media (min-width: 600px)': {
                width: '100%',
              },
              '@media (min-width: 960px)': {
                width: '1180px',

              },
              '@media (min-width: 1280px)': {
                width: '1140px',

              },
              '@media (min-width: 1440px)': {
                width: '1290px',

              },
              '@media (min-width: 1680px)': {
                width: '1540px',

              },
              '@media (min-width: 2400px)': {
                width: '100%',

              },
            },
          }}
          positionActionsColumn="last"
          defaultColumn={{
            minSize: 20,
            maxSize: 9001,
            size: 320,
          }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [100, 200, 500, 1000],
            showFirstButton: true,
            showLastButton: true,
          }}
          initialState={{ 
            pagination: { pageSize: 100, pageIndex: 0 },
            expanded: indexObject,
          }}
          renderDetailPanel={({ row }) => {
            if (!row.getIsExpanded()) {
              const index = expanded.indexOf(row.original.id);
              if (index !== -1) {
                expanded.splice(index, 1);
              }
              expanded[expanded.length - 1]
                && sessionStorage.setItem(
                  "expanded-sites",
                  JSON.stringify(expanded[expanded.length - 1]),
                )
            }
            if (row.getIsExpanded()) {
              if (expandedSite) {
                const rowElement = document.getElementById(`expanded-site-${expandedSite}`)
                if (rowElement) {
                  rowElement.scrollIntoView({ behavior: "smooth", block: 'start' });
                }
              }
              if (!expanded.includes(row.original.id)) {
                expanded.push(row.original.id);
              }
              expanded[expanded.length - 1]
                && sessionStorage.setItem(
                  "expanded-sites",
                  JSON.stringify(expanded[expanded.length - 1]),
                )
            }
            return (
              <div id={`expanded-site-${row.original.id}`}>
                <H5BoldInter mt={4} mb={2}>
                  Site Details
                </H5BoldInter>
                <MaterialReactTable
                  muiTableContainerProps={{
                    sx: {
                      "& thead": {
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                      },
                      "& thead tr:first-of-type th:first-of-type": {
                        paddingLeft: 0,
                        "& > div": {
                          justifyContent: "center",
                        },
                      },
                      "& tbody tr:first-of-type td > p": {
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      },
                      "& tbody tr td:first-of-type": {
                        paddingLeft: 0,
                        "& > div": {
                          paddingLeft: 0,
                          paddingRight: 0,
                          justifyContent: "center",
                        },
                      },
                      border: "1px solid #D9D9D9",
                      borderRadius: "10px",
                      minWidth: "100%",
                      width: "100%",
                      maxWidth: "100%",
                      "@media (min-width: 600px)": {
                        width: "100%",
                      },
                      "@media (min-width: 960px)": {
                        width: "1100px",
                      },
                      "@media (min-width: 1280px)": {
                        width: "1100px",
                      },
                      "@media (min-width: 1440px)": {
                        width: "1100px",
                      },
                      "@media (min-width: 1680px)": {
                        width: "1300px",
                      },
                      "@media (min-width: 2400px)": {
                        width: "100%",
                      },
                    },
                  }}
                  muiTablePaperProps={{
                    sx: {
                      borderRadius: "10px",
                      boxShadow: "none",
                    },
                  }}
                  muiTopToolbarProps={{
                    sx: {
                      display: "none",
                    },
                  }}
                  muiBottomToolbarProps={{
                    sx: {
                      display: "none",
                    },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      lineHeight: "30px",
                      "& .MuiBadge-root": {
                        display: "none",
                      },
                    },
                  }}
                  muiTableHeadRowProps={{
                    sx: {
                      height: "45px",
                      backgroundColor: "#F8FAFC",
                    },
                  }}
                  muiTableBodyRowProps={{
                    sx: {
                      height: "45px",
                      backgroundColor: "white",
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      color: row.original?.enabled ? 'black' : 'grey',
                      fontSize: '14px',
                    }
                  }}
                  columns={siteDetailsColumns}
                  data={[row.original]}
                  enablePagination={false}
                  enableDensityToggle={false}
                  initialState={{
                    density: "compact",
                  }}
                  enableRowActions
                  renderRowActions={({ row }) => (
                    <Box
                      sx={{
                        display: "flex",
                        borderRight: '1px solid rgba(224,224,224,1)', height: '45px', alignItems: 'center', paddingRight: '5px'
                      }}
                    >
                      <Tooltip arrow placement="right" title="Edit">
                        <IconButton
                          sx={{ width: "35px", height: "35px" }}
                          onClick={() => {
                            handleEditSite(convertBlankStringsToNull(row.original), "");
                          }}
                        >
                          <EsgEditIcon color={row.original.enabled ? COLORS.Romance : 'grey'} />
                        </IconButton>
                      </Tooltip>
                      {row.original.enabled === true ? <Tooltip arrow placement="right" title="Deactivate">
                        <IconButton
                          sx={{ width: "35px", height: "35px" }}
                          onClick={() => {
                            handleEditSite(convertBlankStringsToNull(row.original), "Disable");
                          }}
                        >
                          <EsgRedPowerIcon />
                        </IconButton>
                      </Tooltip> : <Tooltip arrow placement="right" title="Reactivate">
                        <IconButton
                          sx={{ width: "35px", height: "35px" }}
                          onClick={() => {
                            handleEditSite(convertBlankStringsToNull(row.original), "Enable");
                          }}
                        >
                          <EsgGreenPowerIcon />
                        </IconButton>
                      </Tooltip>}
                      <Tooltip arrow placement="right" title="Notes">
                        <IconButton
                          sx={{ width: "35px", height: "35px" }}
                          onClick={() => handleEditSiteNotes(convertBlankStringsToNull(row.original), "")}
                        >
                          <EsgNotesIcon color={row.original.notes !== null ? COLORS.Romance : 'grey'} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip arrow placement="right" title="Emission Factor References">
                        <IconButton
                          sx={{ width: "35px", height: "35px" }}
                          onClick={() => handleEmissionFactorReference(row.original?.id)}>
                          <EmissionFactorReferencesIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                />
                <Box sx={{ mt: 7 }}>
                  <SiteAccordion data={row.original} />
                </Box>
              </div>
            )
          }}
        />
      )}

      <AddSiteModal
        isOpen={addSiteModalOpen || EditSiteOpen || editNotesOpen}
        onClose={handleCloseSiteModal}
        editNotes={editNotesOpen}
        allowedValues={getAllowedValues(allowedValues)}
        editSite={EditSite}
        clickType={clickType}
      />
      <DeleteDialog
        isOpen={deleteDialogControls.isOpen}
        onCancel={deleteDialogControls.close}
        onDeleteClick={() => handleDeActivateSite(toDeactivateSite)}
        target="Site"
        title="De-activate Site?"
      />
    </Box>
  );
};
