import { useQuery } from "@tanstack/react-query";

import { UserDTO, getUsers, getUsersBySiteId, getUsersForDataProviderFn } from "api";

import { QUERY_KEYS } from "consts";

export const useGetUsers = (enabled: boolean = true) =>
  useQuery<UserDTO[]>([QUERY_KEYS.USERS], getUsers, { enabled });

export const useGetUsersDataProviderFn = () =>
  useQuery<UserDTO[]>([QUERY_KEYS.USERS], getUsersForDataProviderFn);

export const useUsersBySiteId = (siteId: number) =>
  useQuery<UserDTO[]>([QUERY_KEYS.USERS, QUERY_KEYS.SITES, siteId], () =>
    getUsersBySiteId(siteId),
  );
