import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import {
  CollectorDTO,
  UnitDescription,
  UtilityBillByIdDTO,
  declineUtilityBill,
  getCollectorsBySiteId,
  manuallyApproveUtilityBill,
  recalculateUtilityBill,
  updateUtilityBill,
  updateCollector,
  getCollectorById,
  getUtilityFormat,
} from "api";

import {
  CheckIcon,
  EsgArrowDown,
  EsgArrowUp,
  EsgNotesIcon,
  EsgPencilIcon,
  FileCrossIcon,
  InfoIcon,
  Infounit,
  QuestionIcon,
} from "assets";

import {
  AutocompleteField,
  COLORS,
  DatePickerFormField,
  Esg12Label,
  H5Bold,
  IMaskFormTextField,
  Label12SemiBold,
  Label14SemiBold,
  TextField,
  USNumberMask2,
  UTILITY_BILL_STATUSES,
  UTILITY_BILL_TYPES,
  useDialogControls,
  Loading,
} from "components";
import { IMaskFormTextFieldNumber } from "components/inputs/imask-text-field-number";

import {
  useAllCollectors,
  useCollectorsBySiteId,
  useGetAllowedValues,
  useNotify,
} from "hooks";

import { QUERY_KEYS } from "consts";

import { MaybeNull } from "types";

import { useUserRole } from "recoils";

import { definedValueOrDefault, getLoggedUserData, isNullOrUndefined } from "utils";

import AddOtherSitesDialog from "./add-site-dialog";
import { COLLECTOR_LABEL } from "./consts";
import { UtilityBillFormData } from "./types";
import { UtilityBillSaveChangesDialog } from "./utility-bill-save-changes-dialog";
import {
  compareCollector,
  getCollectorString,
  getIsWarningFields,
  getTextFieldNoErrorProps,
  getUtilityBillUpdateDTO,
  sendUtilityBillNotificationMessage,
} from "./utils";

import { Anomaly } from "../components";
import DateFormatEditor from "./date-format-editor";
import ValueFormatEditor from "./value-format-editor";

export const UtilityBillFormView: FunctionComponent<{
  utilityBill: UtilityBillByIdDTO;
  isLoading: boolean;
}> = ({ utilityBill, isLoading }) => {

  // ---- Hooks ----
  const notify = useNotify();
  const queryClient = useQueryClient();
  const userRole = useUserRole();
  const saveChangesDialogControls = useDialogControls();
  const loggedUserData = getLoggedUserData();

  // ---- State ----
  const [isManualApproveDisabled, setIsManualApproveDisabled] =
    useState<boolean>(false);
  const [initialBtnValue, setInitialBtnValue] = useState<any>();
  const [buttonName, setButtonName] = useState("");
  const [openAddSiteDialog, setOpenAddSiteDialog] = useState(false);
  const [openDateFormatEditor, setOpenDateFormatEditor] = useState(false);
  const [openValueFormatEditor, setOpenValueFormatEditor] = useState(false);
  const [valueFormatName, setValueFormatName] = useState("");
  const [showsubType, setShowsubType] = useState(false);
  const [newSite, setNewSite] = useState<any>([]);
  const [collectorsData, setCollectorsData] = useState<any>([]);
  const [unitCostValue, setUnitCostValue] = useState("");
  const [co2Emission, setCo2Emission] = useState<MaybeNull<number>>(utilityBill.co2Emission);
  const [utilitySelected, setUtilitySelected] = useState(false);
  const [availableUnits, setAvailableUnits] = useState<UnitDescription[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [openNotesEdit, setOpenNotesEdit] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>("");
  const [notesError, setNotesError] = useState<boolean>(false);



  // ---- API's ----
  const { data: allowedValues } = useGetAllowedValues();
  const { isLoading: isRecalculating } = useMutation(recalculateUtilityBill);
  const { mutateAsync: manuallyApprove, isLoading: isManuallyApproving } = useMutation(manuallyApproveUtilityBill);
  const { mutateAsync: update, isLoading: isUpdating } = useMutation(updateUtilityBill);
  const { mutateAsync: decline, isLoading: isDeclining } = useMutation(declineUtilityBill);

  const { mutateAsync: updateCollectorRequest } = useMutation(updateCollector);
  const { data: collectorsFromApi } =
    utilityBill.siteId === 0
      ? // eslint-disable-next-line
      useAllCollectors()
      : // eslint-disable-next-line
      useCollectorsBySiteId(utilityBill.siteId);

  useEffect(() => {
    const fetchCollectorsData = async () => {
      try {
        const data = await getCollectorsBySiteId(newSite.id);
        setCollectorsData(data);
        queryClient.invalidateQueries([
          QUERY_KEYS.COLLECTORS,
          QUERY_KEYS.SITES,
          newSite?.id,
        ]);
      } catch (error) {
        console.error("Error fetching collectors data:", error);
      }
    };

    if (newSite && newSite.id !== undefined) {
      fetchCollectorsData();
    }
    // eslint-disable-next-line
  }, [newSite, newSite.id]);

  const defaultValues = useMemo(() => {
    const getSelectedCollector = () => {
      if (newSite.id !== undefined) {
        return collectorsData?.find(
          (c: any) => c.id === utilityBill.collectorId,
        );
      }
      return collectorsFromApi?.find(
        (c: any) => c.id === utilityBill.collectorId,
      );
    };

    const selectedCollector = getSelectedCollector();
    return {
      siteName: definedValueOrDefault(utilityBill?.siteName, ""),
      provider: definedValueOrDefault(selectedCollector?.providerName, ""),
      measuredUnit: definedValueOrDefault(utilityBill?.measuredUnit, ""),
      measuredValue: definedValueOrDefault(
        utilityBill.measuredValue?.toString().replace(/,/g, "."),
        "",
        String,
      ),
      startDate: moment(utilityBill.startDate, "YYYY-MM-DD"),
      endDate: moment(utilityBill.endDate, "YYYY-MM-DD"),
      amount: definedValueOrDefault(
        utilityBill.amount?.toString().replace(/,/g, "."),
        "",
        String,
      ),
      currency: definedValueOrDefault(utilityBill.currency, ""),
      meterNumber: definedValueOrDefault(utilityBill.meterNumber, ""),
      accountNumber: definedValueOrDefault(utilityBill.accountNumber, ""),
      utilityTypeName: definedValueOrDefault(utilityBill.type, ""),
      collector: utilityBill.collectorId
        ? selectedCollector
          ? getCollectorString(selectedCollector)
          : getCollectorString({
            accountNumber: utilityBill.accountNumber,
            utilityTypeName: utilityBill.type,
            meterNumber: utilityBill.meterNumber,
          } as CollectorDTO)
        : getCollectorString({
          accountNumber: "-",
          utilityTypeName: "-",
          meterNumber: "-",
        } as CollectorDTO),
      subtype: definedValueOrDefault(
        selectedCollector
          ? selectedCollector.utilitySubType
          : utilityBill.subtype,
        "",
      ),
      traveledMiles: definedValueOrDefault(
        utilityBill.milesTraveled,
        "",
        String,
      ),
      unitCost: definedValueOrDefault(utilityBill.unitCost, ""),
      spendBased: definedValueOrDefault(utilityBill.spendBased, false),
      notes: definedValueOrDefault(selectedCollector?.notes, ""),
      collectorId: definedValueOrDefault(utilityBill.collectorId, 0),
      siteId: utilityBill.siteId,
    };
  }, [utilityBill, collectorsFromApi, collectorsData, newSite.id]);

  // ---- Use Form Hook ----
  const { register, control, watch, reset, handleSubmit, setValue, formState } =
    useForm<UtilityBillFormData>({
      defaultValues,
      mode: "onChange",
    });

  const watchValues: any = watch();

  useEffect(() => {
    if (watchValues.utilityTypeName && allowedValues) {
      const selectedUtilityTypeInfo = allowedValues.utilityTypes.find(
        (ut: any) =>
          ut.name.toLowerCase() === watchValues.utilityTypeName.toLowerCase(),
      );

      if (selectedUtilityTypeInfo) {
        setAvailableUnits(selectedUtilityTypeInfo.unitDescription);
      }
    }
  }, [watchValues.utilityTypeName, allowedValues]);

  useEffect(() => {
    reset(defaultValues);
    setNotes(defaultValues.notes);
  }, [defaultValues, reset]);

  useEffect(() => {
    setCo2Emission(utilityBill.co2Emission);
  }, [utilityBill.co2Emission, utilityBill.usageDifferenceToPreviousBill]);

  useEffect(() => {
    if (watchValues.collector && collectorsFromApi && collectorsData) {
      const selectedCollector = (
        newSite.id !== undefined ? collectorsData : collectorsFromApi
      ).find((c: any) => compareCollector(watchValues.collector, c));

      if (selectedCollector) {
        setValue("siteName", selectedCollector.siteName || "");
        setValue("provider", selectedCollector.providerName || "");
        if (utilitySelected) {
          setValue("measuredUnit", selectedCollector.consumptionUnit || "");
          setValue("currency", selectedCollector.currency || "");
          setValue("accountNumber", selectedCollector.accountNumber || "");
          setValue("meterNumber", selectedCollector.meterNumber || "");
          setValue("utilityTypeName", selectedCollector.utilityTypeName || "");
          setValue("subtype", selectedCollector.utilitySubType || "");
        }
      }
    }
  }, [
    watchValues.collector,
    collectorsFromApi,
    setValue,
    utilitySelected,
    collectorsData,
    newSite,
    showsubType,
  ]);

  useEffect(() => {
    // eslint-disable-next-line
    const all_adornment: any = document.querySelectorAll(
      ".MuiInputAdornment-root button",
    );
    if (all_adornment) {
      all_adornment.forEach((node: any) => {
        // eslint-disable-next-line
        node.tabIndex = -1;
      });
    }
  }, [utilityBill]);

  useEffect(() => {
    if (watchValues.currency && watchValues.measuredUnit) {
      const unitCost = `${watchValues.currency}/${watchValues.measuredUnit}`;
      setUnitCostValue(unitCost);
    } else {
      setUnitCostValue("");
    }
  }, [watchValues.currency, watchValues.measuredUnit]);

  const isDefaultCollectorInOptions = useMemo(
    () =>
      (newSite.id !== undefined
        ? collectorsData
        : collectorsFromApi || []
      ).find((o: any) => compareCollector(defaultValues.collector, o)),
    [collectorsFromApi, defaultValues.collector, collectorsData, newSite.id],
  );

  const collectorsOptions = (
    newSite.id !== undefined ? collectorsData : collectorsFromApi || []
  )
    .filter((item: any) => !isNullOrUndefined(item.accountNumber))
    .map(getCollectorString);

  if (!isDefaultCollectorInOptions) {
    collectorsOptions.push(defaultValues.collector);
  }

  const isWarningFields = getIsWarningFields(defaultValues, watchValues, {
    collector: "- / - / -",
  });

  const handleConfirmData = () => {
    setButtonName("Confirm");
    if (utilityBill?.errorMessage?.includes("Duplicate Bill Uploaded")) {
      saveChangesDialogControls.open();
    } else {
      handleManualApproveClick();
    }
    if (
      utilityBill?.endDate &&
      utilityBill?.startDate &&
      utilityBill?.endDate < utilityBill?.startDate
    ) {
      notify.error(
        "The date range error needs to be resolved before you can confirm this data",
      );
    }
  };

  const handleManualApproveClick = handleSubmit(() =>
    manuallyApprove(utilityBill.id, {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QUERY_KEYS.UTILITY_BILLS,
          utilityBill.id,
        ]);
        sendUtilityBillNotificationMessage(
          notify.success,
          "Successfully manually approved utility bill",
          utilityBill.filename,
        );
      },
      onError: (e: any) => {
        if (e?.response?.data) {
          notify.error(e?.response?.data)
        } else {
          sendUtilityBillNotificationMessage(
            notify.error,
            "Failed to manually approve utility bill",
            utilityBill.filename,
          )
        }
      }
    }),
  );

  useEffect(() => {
    const initBtnValue =
      (defaultValues as any).endDate !== "" &&
      (defaultValues as any).startDate !== "" &&
      defaultValues.measuredUnit !== "" &&
      defaultValues.measuredValue !== "" &&
      defaultValues.meterNumber !== "" &&
      defaultValues.accountNumber !== "" &&
      defaultValues.utilityTypeName !== "" &&
      defaultValues.subtype !== "" &&
      defaultValues.amount !== "" &&
      defaultValues.currency !== "";
    setInitialBtnValue(initBtnValue);
  }, [defaultValues]);

  const handleSaveClick = handleSubmit((formData) => {
    if (buttonName === "Confirm") {
      manuallyApprove(utilityBill.id, {
        onSuccess: () => {
          queryClient.invalidateQueries([
            QUERY_KEYS.UTILITY_BILLS,
            utilityBill.id,
          ]);
          saveChangesDialogControls.close();
          sendUtilityBillNotificationMessage(
            notify.success,
            "Successfully manually approved utility bill",
            utilityBill.filename,
          );
        },
        onError: () =>
          sendUtilityBillNotificationMessage(
            notify.error,
            "Failed to manually approve utility bill",
            utilityBill.filename,
          ),
      });
    } else {
      update(
        getUtilityBillUpdateDTO(
          utilityBill.id,
          formData && formData,
          newSite.id !== undefined ? collectorsData : collectorsFromApi,
        ),
        {
          onSuccess: (response: any) => {
            queryClient.setQueryData([
              QUERY_KEYS.UTILITY_BILLS,
              utilityBill.id,
            ], response);
            setIsManualApproveDisabled(false);
            saveChangesDialogControls.close();
            if (utilityBill.spendBased) {
              // window.location.reload();
            }
            sendUtilityBillNotificationMessage(
              notify.success,
              "Successfully saved utility bill",
              utilityBill.filename,
            );
          },
          onError: () =>
            sendUtilityBillNotificationMessage(
              notify.error,
              "Failed to save utility bill",
              utilityBill.filename,
            ),
        },
      );
    }
    setButtonName("");
    setNewSite([]);
  });

  const handleDeclineClick = handleSubmit(() =>
    decline(utilityBill.id, {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QUERY_KEYS.UTILITY_BILLS,
          utilityBill.id,
        ]);
        sendUtilityBillNotificationMessage(
          notify.success,
          "Successfully rejected utility bill",
          utilityBill.filename,
        );
      },
      onError: () =>
        sendUtilityBillNotificationMessage(
          notify.error,
          "Failed to reject utility bill",
          utilityBill.filename,
        ),
    }),
  );

  const getAnomaliesMessage = (
    type: string | undefined | null,
    value: string | undefined | null,
    id: string | undefined | null,
  ) => ({
    anomalyType: type === "Date" ? "Date Range" : type === "Last Threshold Exceeded" ? "Usage | MoM" : type === "Y2Y Threshold Exceeded" ? "Usage | YoY" : type,
    message:
      type === "Date" || type === "Duplicate_upload" || type === "Previous usage zero" || type === "Last Threshold Exceeded" || type === "Y2Y Threshold Exceeded" || type === "Cost"
        ? value
        : type === "Site Period Blocked"
          ? "The bill is for a site and time period that's already signed off or locked. Including this bill may cause finalized emission numbers to change"
          : `${type} is ${value} different from the last bill uploaded for this utility provider`,
    previousbillId: id,
  });

  const getAnomaliesData = () =>
    utilityBill.anomalies?.map((item) =>
      getAnomaliesMessage(
        item.message?.includes("Cost")
          ? "Cost"
          : item.anomalyType === "BILL_DATE_RANGE"
            ? "Date"
            : item.anomalyType === "MULTIPLE_UPLOAD"
              ? "Duplicate_upload"
              : item.anomalyType === "PREVIOUS_USAGE_ZERO"
                ? "Previous usage zero"
                : item.anomalyType === "LAST_THRESHOLD_EXCEEDED"
                  ? "Last Threshold Exceeded"
                  : item.anomalyType === "Y2Y_THRESHOLD_EXCEEDED"
                    ? "Y2Y Threshold Exceeded"
                    : item.anomalyType === "SITE_PERIOD_BLOCKED"
                      ? "Site Period Blocked"
                      : "Usage",
        item.anomalyType === "BILL_DATE_RANGE"
          ? item.message
          : item.anomalyType === "PREVIOUS_USAGE_ZERO"
            ? item.message
            : item.anomalyType === "LAST_THRESHOLD_EXCEEDED"
              ? item.message
              : item.anomalyType === "Y2Y_THRESHOLD_EXCEEDED"
                ? item.message
                : item.anomalyType === "MULTIPLE_UPLOAD"
                  ? item.message
                  : item.anomalyType === "SITE_PERIOD_BLOCKED"
                    ? item.message
                    : item.message?.split(" ").pop(),
        item.previousBillGuid !== null ? item.previousBillGuid : "",
      ),
    );

  interface LabelWithButtonProps {
    label: React.ReactNode;
    onClickOtherSites: () => void;
  }

  useEffect(() => {
    if (
      watchValues.utilityTypeName === "Refrigerants" ||
      watchValues.utilityTypeName === "Gasoline Vehicle" ||
      watchValues.utilityTypeName === "Hybrid Vehicle" ||
      watchValues.utilityTypeName === "Diesel Vehicle"
    ) {
      setShowsubType(true);
    } else setShowsubType(false);
  }, [watchValues.utilityTypeName, showsubType]);

  const renderLabelwithButton = ({
    label,
    onClickOtherSites,
  }: LabelWithButtonProps) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>{label}</span>
      <Button
        sx={{
          fontSize: "10px",
          padding: "4px",
          borderRadius: "6px",
          marginLeft: "5px",
          maxWidth: "66px",
          maxHeight: "18px",
        }}
        variant="contained"
        color="primary"
        onClick={onClickOtherSites}
      >
        {newSite.id === undefined ? "Other Sites" : "Change"}
      </Button>
    </div>
  );

  const handleOtherSitesButtonClick = () => {
    setOpenAddSiteDialog(true);
  };

  const handleApplyNewSite = () => {
    setOpenAddSiteDialog(false);
    setValue("siteName", newSite.name);
  };

  const startDate = watch("startDate");
  const endDate = watch("endDate");

  const isButtonDisabled = new Date(startDate) > new Date(endDate);

  const [debouncedStartDate, setDebouncedStartDate] = useState(startDate);
  const [debouncedEndDate, setDebouncedEndDate] = useState(endDate);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    setIsTyping(true);

    const handler = setTimeout(() => {
      setDebouncedStartDate(startDate);
      setDebouncedEndDate(endDate);
      setIsTyping(false);
    }, 800);

    return () => clearTimeout(handler);
  }, [startDate, endDate]);

  useEffect(() => {
    if (!isTyping && debouncedStartDate && debouncedEndDate) {
      const start = new Date(debouncedStartDate);
      const end = new Date(debouncedEndDate);

      if (start > end) {
        notify.error(
          "Invalid Date Entry: The start date cannot be later than the end date. Please review and correct the dates you’ve entered."
        );
      }
    }
  }, [debouncedStartDate, debouncedEndDate, notify, isTyping]);

  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleEditNotes = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length <= 250) {
      setNotes(value);
      setNotesError(false);
    } else {
      setNotesError(true);
    }
  };

  const handleSaveCollectorNotes = async () => {
    const currentCollector = await getCollectorById(defaultValues.collectorId);
    const noteData = {
      accountNumber: currentCollector.accountNumber,
      allowedDocuments: currentCollector.allowedDocuments,
      frequency: currentCollector.frequency,
      id: currentCollector.id,
      providerName: currentCollector.providerName,
      consumptionUnit: currentCollector.consumptionUnit,
      currency: currentCollector.currency,
      siteId: defaultValues.siteId,
      utilityTypeId: allowedValues!.utilityTypes.find((ut: any) => ut.name === defaultValues.utilityTypeName)!.id,
      notes,
    }

    await updateCollectorRequest(noteData, {
      onError: () => {
        notify.error(`Some error has happened while updating notes!`);
      },
      onSuccess: () => {
        notify.success(`Notes successfully Updated!`);
        setOpenNotesEdit(false);
      },
    });

    handleClosePopover();
  };

  const handleCloseEditNotes = () => {
    setNotes(defaultValues.notes);
    setOpenNotesEdit(false);
  };

  const handleOpenEditNotes = async () => {
    setOpenNotesEdit(true);
  };

  const { data: formatData, mutate: getFormats } = useMutation(getUtilityFormat)

  useEffect(() => {
    if (defaultValues?.collectorId) {
      getFormats(defaultValues?.collectorId)
    }
  }, [defaultValues?.collectorId, getFormats])

  const isDisable = utilityBill?.status === "LOCKED" || utilityBill?.status === "SIGNED"

  return (
    <>
      <Box component="form" sx={{ flex: 1, mt: 2 }}>
        <Box component="section" mb={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: 2,
            }}
          >
            <H5Bold mb={2}>Client and provider</H5Bold>
            <div>
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "319px",
                  height: "29px",
                  padding: "0 24px 0 12px",
                  borderRadius: 2,
                }}
                aria-describedby={id}
                variant="outlined"
                onClick={handleClickPopover}
                disabled={notes === null || notes === ""}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                    gap: 1,
                  }}
                >
                  <EsgNotesIcon />
                  Notes for Collector
                </Box>
                {open ? <EsgArrowDown /> : <EsgArrowUp color={notes === null || notes === "" ? 'grey' : COLORS.Romance} />}
              </Button>
              <Popover
                sx={{
                  marginTop: 1,
                  "& > div:nth-of-type(3)": {
                    borderRadius: 3,
                    border: "1px solid #D5D5D5",
                  },
                }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Box
                  sx={{
                    p: 2,
                    width: "319px",
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={handleOpenEditNotes} //eslint-disable-line
                  >
                    <EsgPencilIcon />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontStyle: "italic",
                      fontFamily: "Inter",
                    }}
                  >
                    {notes}
                  </Typography>
                </Box>
              </Popover>
            </div>
          </Box>
          <Box mb={2}>
            <TextField
              label="Site"
              fullWidth
              {...register("siteName")}
              disabled
            />
          </Box>
          <TextField
            label="Provider"
            fullWidth
            disabled
            {...register("provider")}
          />
        </Box>
        <Box component="section" mb={3}>
          <H5Bold mb={2}>Basic data</H5Bold>
          {/* Dates */}
          <Stack direction="row" mb={2} spacing={3}>
            <Box sx={{ flex: 1 }}>
              <DatePickerFormField
                name="startDate"
                label="Start date"
                control={control}
                disabled={userRole.isAuditor || isDisable}
                {...getTextFieldNoErrorProps("startDate", isWarningFields)}
              />
              {loggedUserData.superadmin === true && <Esg12Label onClick={() => setOpenDateFormatEditor(true)} sx={{ textDecoration: 'underline', mt: '6px', cursor: 'pointer' }}>Change Value format?</Esg12Label>}
            </Box>
            <Box sx={{ flex: 1 }}>
              <DatePickerFormField
                name="endDate"
                label="End date"
                control={control}
                disabled={userRole.isAuditor || isDisable}
                {...getTextFieldNoErrorProps("endDate", isWarningFields)}
              />
            </Box>
          </Stack>
          <Stack direction="row" spacing={3}>
            <Controller
              name="collector"
              control={control}
              // rules={{ required: true }}
              render={({ field }) => (
                <AutocompleteField
                  key={utilityBill.id}
                  textFieldProps={{
                    label: renderLabelwithButton({
                      label: COLLECTOR_LABEL,
                      onClickOtherSites: handleOtherSitesButtonClick,
                    }),
                    ...getTextFieldNoErrorProps("collector", isWarningFields),
                    disabled: userRole.isAuditor || isDisable,
                  }}
                  autocompleteProps={{
                    ...field,
                    onChange: (e, data) => {
                      field.onChange(data);
                      setUtilitySelected(true);
                    },
                    options: Array.from(
                      new Set(collectorsOptions.map((item: any) => item)),
                    ),
                    // options: collectorsOptions,
                    disabled: userRole.isAuditor || isDisable,
                  }}
                />
              )}
            />

            {UTILITY_BILL_TYPES["VEHICLE FUEL"] === utilityBill.type && (
              <IMaskFormTextField
                textFieldProps={{
                  label: "Traveled miles",
                  fullWidth: true,
                  // @ts-ignore
                  mask: USNumberMask2,
                  disabled: userRole.isAuditor,
                  ...getTextFieldNoErrorProps("traveledMiles", isWarningFields),
                }}
                control={control}
                name="traveledMiles"
              // rules={{ required: true }}
              />
            )}
          </Stack>
          <Stack direction="row" mb={2} mt={3} spacing={2.5}>
            <IMaskFormTextField
              textFieldProps={{
                label: "Utility type",
                fullWidth: true,
                disabled: true,
              }}
              control={control}
              name="utilityTypeName"
            />

            {showsubType && (
              <IMaskFormTextField
                textFieldProps={{
                  label: "Subtype",
                  fullWidth: true,
                  disabled: true,
                }}
                control={control}
                name="subtype"
              />
            )}

            <IMaskFormTextField
              textFieldProps={{
                label: "Account Number",
                fullWidth: true,
                disabled: true,
              }}
              control={control}
              name="accountNumber"
            />

            <IMaskFormTextField
              textFieldProps={{
                label: "Meter Number",
                fullWidth: true,
                disabled: true,
              }}
              control={control}
              name="meterNumber"
            />
          </Stack>
        </Box>
        {/* Invoice Items */}
        <Box component="section" mb={3}>
          <H5Bold mb={2}>Invoice items</H5Bold>
          <Stack direction="row" mb={2} spacing={3}>
            <Box sx={{ flex: 1 }}>
              <IMaskFormTextFieldNumber
                {...register("measuredValue")}
                control={control}
                name="measuredValue"
                label="Usage"
                disabled={isDisable}
              />
              {loggedUserData.superadmin === true && <Esg12Label onClick={() => {
                setOpenValueFormatEditor(true)
                setValueFormatName("usageFormat")
              }} sx={{ textDecoration: 'underline', mt: '6px', cursor: 'pointer' }}>Change Value format?</Esg12Label>}
            </Box>
            <Box sx={{ flex: 1 }}>
              <Controller
                name="measuredUnit"
                control={control}
                render={({ field }) => (
                  <AutocompleteField
                    disabled={isDisable}
                    textFieldProps={{
                      label: (
                        <span>
                          Unit{" "}
                          {utilityBill?.unitCalculationFormula && (
                            <Tooltip
                              title={
                                <pre>{utilityBill?.unitCalculationFormula}</pre>
                              }
                              placement="top"
                              arrow
                            >
                              <IconButton
                                aria-label="info"
                                size="small"
                                sx={{
                                  maxHeight: "23px",
                                  maxWidth: "23px",
                                  mt: -0.4,
                                }}
                              >
                                <Infounit />
                              </IconButton>
                            </Tooltip>
                          )}
                        </span>
                      ),
                      ...getTextFieldNoErrorProps("measuredUnit", isWarningFields),
                      fullWidth: true, // Ensure it stretches to full width
                    }}
                    autocompleteProps={{
                      ...field,
                      disabled: isDisable,
                      onChange: (e, data) => {
                        field.onChange(data);
                      },
                      options: availableUnits
                        ? availableUnits.map(
                          (item) => `${item.unit} (${item.description})`
                        )
                        : [],
                    }}
                  />
                )}
              />
            </Box>
          </Stack>
          <Stack direction="row" spacing={3}>
            <Box sx={{ flex: 1 }}>
              <IMaskFormTextFieldNumber
                control={control}
                name="amount"
                label="Cost"
                disabled={isDisable}
              />
              {loggedUserData.superadmin === true && <Esg12Label onClick={() => {
                setOpenValueFormatEditor(true)
                setValueFormatName("costFormat")
              }} sx={{ textDecoration: 'underline', mt: '6px', cursor: 'pointer' }}>Change Value format?</Esg12Label>}
            </Box>
            <Box sx={{ flex: 1 }}>
              <Controller
                name="currency"
                control={control}
                // rules={{ required: REQUIRED_VALIDATION_MESSAGE }}
                render={({ field }) => (
                  <AutocompleteField
                    textFieldProps={{
                      label: (
                        <span>
                          Currency{" "}
                          {utilityBill.currencyCalculationFormula !== null && (
                            <Tooltip
                              title={
                                <pre>
                                  {utilityBill.currencyCalculationFormula}
                                </pre>
                              }
                              placement="top"
                              arrow
                            >
                              <IconButton
                                aria-label="info"
                                size="small"
                                sx={{
                                  maxHeight: "23px",
                                  maxWidth: "23px",
                                  mt: -0.4,
                                }}
                              >
                                {utilityBill.currencyCalculationFormula !==
                                  null && <Infounit />}
                              </IconButton>
                            </Tooltip>
                          )}
                        </span>
                      ),
                      ...getTextFieldNoErrorProps("currency", isWarningFields),
                    }}
                    autocompleteProps={{
                      ...field,
                      disabled: isDisable,
                      onChange: (e, data) => {
                        field.onChange(data);
                      },
                      options: allowedValues ? allowedValues.currencies : [],
                    }}
                  />
                )}
              />
            </Box>
          </Stack>
          {utilityBill.spendBased && (
            <Stack direction="row" spacing={3} mt={2}>
              <div style={{ display: "flex", width: "50%" }}>
                <TextField
                  label={
                    <span>
                      Unit Cost Value{" "}
                      <Tooltip
                        title="This value is defined as an assumption in Site Management and is used to calculate usage for this bill"
                        placement="top"
                      >
                        <IconButton
                          aria-label="info"
                          size="small"
                          sx={{ maxHeight: "23px", maxWidth: "23px" }}
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </span>
                  }
                  helperText={
                    <FormHelperText
                      sx={{
                        color: "red",
                        fontFamily: "Manrope",
                        fontSize: "11px",
                        whiteSpace: "pre-wrap",
                        overflow: "hidden",
                        width: "400px",

                        "&.MuiFormHelperText-root": {
                          margin: "0px",
                          marginLeft: "-11px",
                          color: "red",
                        },
                      }}
                    >
                      Unit Cost is defined at the Site/Utility Provider level
                      and needs to be modified under Site Management
                    </FormHelperText>
                  }
                  fullWidth
                  disabled
                  value={utilityBill.unitCost}
                />
              </div>
              <div style={{ flex: 1 }}>
                <TextField label="Unit Cost" fullWidth value={unitCostValue} />
              </div>
            </Stack>
          )}
        </Box>

        <Box mb={4}>
          <Label12SemiBold>Calculated emission</Label12SemiBold>
          <Stack direction="row" alignItems="center">
            {utilityBill.type === "Refrigerants" ? (
              <Label14SemiBold>
                {co2Emission !== null && co2Emission.toFixed(4) !== "0.0000"
                  ? co2Emission.toFixed(4)
                  : "-"}{" "}
                t CO<sub>2</sub>
              </Label14SemiBold>
            ) : (
              <Label14SemiBold>
                {co2Emission === null ? "-" : co2Emission.toFixed(4)} t CO
                <sub>2</sub>
              </Label14SemiBold>
            )}
            {utilityBill.explanation && (
              <Tooltip
                title={
                  <pre>
                    {(() => {
                      const indexOfEmissions = utilityBill.explanation.indexOf(
                        "Emissions Factors Applied:",
                      );

                      if (indexOfEmissions !== -1) {
                        return [
                          <span
                            key="bold"
                            style={{
                              fontWeight: "1000",
                              fontFamily: "Manrope",
                              fontSize: "13px",
                            }}
                          >
                            {utilityBill.explanation.substring(
                              0,
                              indexOfEmissions +
                              "Emissions Factors Applied:".length,
                            )}
                          </span>,
                          utilityBill.explanation.substring(
                            indexOfEmissions +
                            "Emissions Factors Applied:".length,
                          ),
                        ];
                      }
                      return utilityBill.explanation?.replaceAll("null", "-");
                    })()}
                  </pre>
                }
              >
                <Box sx={{ ml: 1 }}>
                  <QuestionIcon />
                </Box>
              </Tooltip>
            )}
          </Stack>
        </Box>
        {!userRole.isAuditor && (
          <>
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              startIcon={<CheckIcon />}
              onClick={saveChangesDialogControls.open}
              disabled={
                isButtonDisabled ||
                isRecalculating ||
                isUpdating ||
                isManuallyApproving ||
                (initialBtnValue && !formState.isDirty) ||
                (UTILITY_BILL_STATUSES.CLEANED && !formState.isDirty)
              }
            >
              Save changes
            </Button>
            {utilityBill.status ===
              UTILITY_BILL_STATUSES.VERIFICATION_FAILED && (
                <Button
                  sx={{ ml: 2 }}
                  variant="outlined"
                  // type="submit"
                  name="manually-approve"
                  startIcon={<CheckIcon />}
                  onClick={handleConfirmData}
                  disabled={
                    isButtonDisabled ||
                    isRecalculating ||
                    isUpdating ||
                    isManuallyApproving ||
                    isManualApproveDisabled ||
                    Object.keys(formState.errors).length !== 0
                  }
                >
                  Confirm data
                </Button>
              )}
          </>
        )}
        {userRole.isAuditor && (
          <Button
            variant="outlined"
            type="submit"
            name="reject"
            color="error"
            startIcon={
              <FileCrossIcon
                fill={
                  utilityBill.status === UTILITY_BILL_STATUSES.DECLINED
                    ? COLORS.disabled
                    : COLORS.red
                }
              />
            }
            onClick={handleDeclineClick}
            disabled={
              isDeclining ||
              utilityBill.status === UTILITY_BILL_STATUSES.DECLINED
            }
          >
            Reject
          </Button>
        )}
      </Box>
      {isUpdating || isLoading
        ? <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          minHeight: '100px',
          marginTop: 2,
        }}
        >
          <Loading sx={{ width: '50px' }} />
        </Box>
        : <Box
          sx={{
            marginTop: "20px",
            display: "flex",
            flexWrap: " wrap",
            paddingRight: "40px",
          }}
        >
          {getAnomaliesData()?.map(({ anomalyType, message, previousbillId }, index) => (
            <Box
              mb={3}
              sx={{ minWidth: "fit-content", marginLeft: "20px" }}
              key={`${index + 1}-anomaly`}
            >
              <Anomaly
                anomalyType={anomalyType || null}
                message={message as string}
                previousBillGuid={previousbillId as string}
              />
            </Box>
          ))}
        </Box>
      }

      <UtilityBillSaveChangesDialog
        handleSaveClick={handleSaveClick}
        isOpen={saveChangesDialogControls.isOpen}
        onClose={saveChangesDialogControls.close}
        varient={
          utilityBill?.errorMessage?.includes("Duplicate Bill Uploaded")
            ? "duplicateBill"
            : ""
        }
      />
      <AddOtherSitesDialog
        isOpen={openAddSiteDialog}
        onClose={() => setOpenAddSiteDialog(false)}
        handleSaveClick={handleApplyNewSite}
        setNewSite={setNewSite}
      />
      <DateFormatEditor
        isOpen={openDateFormatEditor}
        onClose={() => setOpenDateFormatEditor(false)}
        collectorId={defaultValues?.collectorId}
        data={formatData}
      />
      <ValueFormatEditor
        isOpen={openValueFormatEditor}
        onClose={() => setOpenValueFormatEditor(false)}
        collectorId={defaultValues?.collectorId}
        valueFormatName={valueFormatName}
        data={formatData}
      />

      <Dialog
        open={openNotesEdit}
        onClose={handleCloseEditNotes}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Notes
        </DialogTitle>
        <DialogContent sx={{ px: "24px", width: "500px" }}>
          <Box>
            <TextField
              sx={{ width: "100%" }}
              onChange={handleEditNotes}
              value={notes}
              error={notesError}
              helperText={
                notesError
                  ? "Notes length should not exceed 250 characters"
                  : ""
              }
              multiline
              rows={3}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            mb={2}
            ml={2}
          >
            <Grid item sx={{ pr: '16px' }}>
              <Button onClick={handleCloseEditNotes}>Cancel</Button>
              <Button
                onClick={handleSaveCollectorNotes}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};